// import * as React from 'react';
import {
  Expander,
  ExpanderItem,
  ThemeProvider,
} from '@aws-amplify/ui-react';
import { FilterOrg } from './FilterOrg';
import '@aws-amplify/ui-react/styles.css';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { expandImageTheme } from '../themes';
import { useSearchParams } from 'react-router-dom';
import '../AudiType.css';


export const ExpandImage = ({ onFilter, onExpandChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const organizationName = searchParams.get('organizationName');

  const handleExpandChange = () => {
    const newValue = !isOpen;
    setIsOpen(newValue);
    onExpandChange(newValue);
  };

  return (
    <ThemeProvider theme={expandImageTheme} colorMode="light">
      <Expander type='single' isCollapsible={organizationName ? true : false} onValueChange={handleExpandChange} defaultValue="item-1">
        <ExpanderItem title="Image Ids" value="item-1" style={{ fontWeight: 'Bold' }}>
          <FilterOrg onFilter={onFilter} onExpandChange={onExpandChange}></FilterOrg>
        </ExpanderItem>
      </Expander>
    </ThemeProvider>
  );
};
ExpandImage.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onExpandChange: PropTypes.func.isRequired
};
ExpandImage.defaultProps = {
  onExpandChange: () => { }
};