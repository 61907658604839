import { Flex, Button, SelectField } from '@aws-amplify/ui-react';
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listImagePlatforms } from '../graphql/queries';
import PropTypes from 'prop-types';
import '../AudiType.css';



export const FilterOrg = ({ onFilter, onExpandChange }) => {

  // State variables to store data and selections
  const [organizations, setOrganizations] = useState([]);
  const [cloudProviders, setCloudProviders] = useState([]);
  const [regions, setRegions] = useState([]);

  const [selectedOrganizations, setSelectedOrganizations] = useState("");
  const [selectedCloudProvider, setSelectedCloudProvider] = useState("");
  const [selectedRegionList, setSelectedRegionList] = useState([]); // Available regions for the selected cloud provider
  const [selectedRegion, setSelectedRegion] = useState(""); // current selected regions

  const applyImageTableFilter = async () => {
    try {
      const filter = {
        organizationName: selectedOrganizations, // Add selected organization
        platformName: selectedCloudProvider,
        platformRegion: selectedRegion,
      };
      onFilter(filter); // for Passing the filtered images to the parent

      //update URL based on filters
      const params = new URLSearchParams(filter);
      window.history.pushState({}, '', "?" + params.toString());

      // Ensure the parent component's onExpandChange is called to set isExpanded to true
      onExpandChange && onExpandChange(true);
    } catch (error) {
      console.error('Error filtering images:', error.message, error.stack);
    }
  };

  const handleOrganizationChange = (event) => {
    setSelectedOrganizations(event.target.value);
  };
  // Function to handle cloud provider selection and update regions
  const handleCloudProviderChange = (event) => {
    setSelectedCloudProvider(event.target.value);
    setSelectedRegionList(regions[event.target.value] || []);
    setSelectedRegion(null); // Reset selected region

  };
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const initializeFiltersFromURL = () => {
    const params = new URLSearchParams(window.location.search);

    // Use URL params to set initial state
    setSelectedOrganizations(params.get('organizationName') || "");
    setSelectedCloudProvider(params.get('platformName') || "");
    setSelectedRegion(params.get('platformRegion') || "");

  };
  //Effect hook to fetch initial data from GraphQL API
  useEffect(() => {
    initializeFiltersFromURL(); // Initialize filters from URL before fetching data
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCloudProvider && regions[selectedCloudProvider]) {
      setSelectedRegionList(regions[selectedCloudProvider]);
    }
  }, [selectedCloudProvider, regions]);

  // Async function to fetch cloud providers and regions
  const fetchData = async () => {
    try {
      const responsePlatform = await API.graphql(graphqlOperation(listImagePlatforms));
      const orgData = [...new Set(responsePlatform.data.listImagePlatforms.items.map(item => item.organizationName))];
      setOrganizations(orgData);

      // Extracting cloud providers
      const cloudProvidersData = [...new Set(responsePlatform.data.listImagePlatforms.items.map(item => item.platformName))];
      setCloudProviders(cloudProvidersData);

      // Organizing regions by cloud providers
      const regionsByProvider = {};
      responsePlatform.data.listImagePlatforms.items.forEach(item => {
        const providerName = item.platformName;
        const region = item.platformRegion;
        if (!regionsByProvider[providerName]) {
          regionsByProvider[providerName] = new Set();
        }
        regionsByProvider[providerName].add(region);
      });

      // Convert the Sets to arrays
      Object.keys(regionsByProvider).forEach(key => {
        regionsByProvider[key] = [...regionsByProvider[key]];
      });

      setRegions(regionsByProvider);
    } catch (error) {
      console.error('Error fetching platforms:', error);
    }
  };

  return (
    <>
      <Flex direction="row" alignItems="center">
        <SelectField
          placeholder='Select Organization'
          fontFamily='AudiType-ExtendedBold'
          label="Organization"
          onChange={handleOrganizationChange}
          inputStyles={{
            backgroundColor: 'white',
            border: `0.063rem solid #000000`,
            fontFamily: 'AudiType-ExtendedBold',
            fontSize: '0.938rem'
          }}
          options={organizations}
          value={selectedOrganizations}
        >
        </SelectField>
        <SelectField
          placeholder='Select Cloud Provider'
          fontFamily='AudiType-ExtendedBold'
          label="Cloud Provider"
          onChange={handleCloudProviderChange}
          inputStyles={{
            backgroundColor: 'white',
            border: `0.063rem solid #000000`,
            fontFamily: 'AudiType-ExtendedBold',
            fontSize: '0.938rem'
          }}
          options={cloudProviders}
          value={selectedCloudProvider}
        >
        </SelectField>
        <SelectField
          placeholder='Select Region'
          fontFamily='AudiType-ExtendedBold'
          label="Region"
          onChange={handleRegionChange} // Add onChange handler

          disabled={!selectedCloudProvider}
          inputStyles={{
            backgroundColor: 'white',
            border: `0.063rem solid #000000`,
            fontFamily: 'AudiType-ExtendedBold',
            fontSize: '0.938rem'
          }}
          options={selectedRegionList}
          value={selectedRegion}
        >
        </SelectField>

        <Button
          width="6rem"
          height="2.5rem"
          variation="primary"
          fontFamily='AudiType-ExtendedBold'
          size="small"
          loadingText=""
          style={{ color: 'white', marginTop: '1.875rem' }}
          backgroundColor="#000000"
          onClick={applyImageTableFilter}
        >
          FILTER
        </Button>
      </Flex>

    </>
  );
};

FilterOrg.propTypes = {
  onFilter: PropTypes.func.isRequired,
};
