import React from 'react';
import { ImageCollection } from './ImageCollection';


export const CustomImageList = () => {

  return (
    <>
      <ImageCollection />
    </>
  );
};
