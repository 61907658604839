import React from 'react';
import { StyledCodeBlock } from './StyledCodeBlock'

export const AWSTerraformDoc = () => {
  const terraformCode = `  data "aws_ami" "ubuntu" {
    most_recent = true
    filter {
      name   = "name"
      values = ["<ami_name_pattern>"]
    }
    filter {
      name   = "virtualization-type"
      values = ["hvm"]
    }
    owners = ["879919243408"]
  }

  resource "aws_instance" "web" {
    ami           = data.aws_ami.ubuntu.id
    instance_type = "<instance_type>"
    subnet_id     = "<subnet_id>"

    tags = {
      Name = "Your Instance Name Here"
    }
  }`;
  return (
    <div>
      <p>This guide provides a concise walkthrough for using AMI images with Terraform to provision AWS instances.
        We use Terraform to select an Amazon Machine Image (AMI) and then deploy an AWS instance with that image.</p>

      <h3>Prerequisites</h3>
      <ul>
        <li>Terraform installed on your machine.</li>
        <li>An AWS account with the necessary permissions to create resources.</li>
        <li>AWS CLI configured with your credentials.</li>
      </ul>

      <p>First, define the AMI data source to search for the most recent suitable image that matches specific criteria.
        Adjust the values filter to match the naming convention of your images.</p>
      <p>Next, provision an AWS instance using the selected AMI.</p>
      <StyledCodeBlock code={terraformCode} />
      <h3>Parameters</h3>
      <p>Ensure you replace placeholder values (e.g., &lt;instance_type&gt;, &lt;ami_name_pattern&gt;, &lt;subnet_id&gt;, &lt;account_id&gt;) with actual values relevant to your AWS setup.</p>

      <ul>
        <li><strong>ami_name_pattern:</strong> The pattern to match AMI names (e.g., "IF20-UBUNTU-20.04-lts-EKS-arm64-AUDI-DEV-*").</li>
        <li><strong>account_id:</strong> with the AWS account ID that owns the AMI(e.g., "123485307777").</li>
        <li><strong>instance_type:</strong> The type of instance to launch (e.g., “t4g.micro”).</li>
        <li><strong>subnet_id:</strong> The ID of the VPC subnet to launch the instance in(e.g., "subnet-03b8e918a44561234").</li>
      </ul>
    </div>
  );
};

export const AWSCdkDoc = () => {
  const cdkCode = `  const ami = ec2.MachineImage.lookup({
    name: '\${amiNamePattern}',
    owners: ['\${amiOwner}'],
    filters: {
      'virtualization-type': ['hvm'],
    }
  });

  const vpc = ec2.Vpc.fromLookup(this, 'VPC', { isDefault: true });
  const instance = new ec2.Instance(this, 'InstanceIdentifier', {
    vpc,
    instanceType: ec2.InstanceType.of(ec2.InstanceClass.\${instanceClass}, ec2.InstanceSize.\${instanceSize}),
    machineImage: ami
  });
  cdk.Tags.of(instance).add('Name', '\${instanceName}');`;

  return (
    <div>
      <p>This guide provides a straightforward method for utilizing Amazon Machine Images (AMI) with the AWS Cloud Development Kit (CDK) to create and configure AWS EC2 instances. The example demonstrates how to look up an AMI by name and use it to create an instance within a default VPC.</p>

      <h3>Prerequisites</h3>
      <ul>
        <li>AWS CDK installed and setup</li>
        <li>Node.js and npm installed</li>
        <li>AWS account and CLI configured with appropriate permissions</li>
      </ul>

      <p>First, look up the AMI that matches your criteria (e.g., AMI name pattern, ownership, and virtualization type). Then, Retrieve the default VPC where the instance will be launched. Next, create an EC2 instance within the retrieved VPC using the looked-up AMI.</p>
      <StyledCodeBlock code={cdkCode} />
      <p>By following these steps, you can efficiently use AWS CDK to deploy EC2 instances with a specific AMI in your AWS environment. This approach is easily customizable and can be adapted to fit various deployment scenarios by adjusting the parameters and configurations as needed.</p>

    </div>
  );
};

export const ECRTerraformDoc = () => {
  const terraformCode = `data "aws_ecr_repository" "your_repository" {
    name = "your-repo-name" 
  }
  
data "aws_ecr_image" "latest" {
  repository_name = data.aws_ecr_repository.your_repository.name
  image_tag       = "latest"
}
  
resource "aws_iam_role" "ecs_task_execution_role" {
  name = "ecs_task_execution_role"
  
  assume_role_policy = jsonencode({
    Version = "2012-10-17"
    Statement = [
      {
        Action = "sts:AssumeRole"
        Principal = {
          Service = "ecs-tasks.amazonaws.com"
        }
        Effect = "Allow"
        Sid    = ""
      },
    ]
   })
  }
  
resource "aws_ecs_task_definition" "your_task" {
  family                   = "your-task-family"
  network_mode             = "awsvpc"
  requires_compatibilities = ["FARGATE"]
  execution_role_arn       = aws_iam_role.ecs_task_execution_role.arn
  cpu                      = "256"
  memory                   = "512"

  container_definitions = jsonencode([
    {
      name      = "your-container-name"
      image     = "\${data.aws_ecr_repository.your_repository.repository_url}@\${data.aws_ecr_image.latest.image_digest}"
      cpu       = 256
      memory    = 512
      essential = true
      portMappings = [
        {
          containerPort = 80
          hostPort      = 80
          protocol      = "tcp"
        },
      ]
    }
  ])
};`
  return (
    <div>
      <p>This guide provides a concise walkthrough for using Container images to create ECS task with Terraform. We use Terraform to select an image tag and using the Image in an Amazon ECS Task Definition.</p>

      <h3>Prerequisites</h3>
      <ul>
        <li>Terraform installed on your machine.</li>
        <li>An AWS account with the necessary permissions to create resources.</li>
        <li>AWS CLI configured with your credentials.</li>
      </ul>

      <p>First, use the <code class="highlight">aws_ecr_repository</code> data source to fetch details about your repository, and <code class="highlight">aws_ecr_image</code> to get information about the specific image tagged as <code class="highlight">"latest"</code>.</p>
      <p>Next, define an IAM role that the ECS tasks will assume. This role grants necessary permissions for the tasks to run. After that, create an ECS task definition that uses the specified ECR image. </p>
      <StyledCodeBlock code={terraformCode} />
      <h3>Parameters</h3>
      <p>Ensure you replace placeholder values (e.g., &lt;name&gt;, &lt;image_tag&gt;) with actual values relevant to your AWS setup.</p>

      <ul>
          <li><b>ECR Repository Name </b>(<code>aws_ecr_repository</code> data source): The name should match the name of your existing Amazon ECR repository. Replace <code>"your-test-repo"</code> with the actual name of your repository.</li>
          <li><b>Image Tag </b> (<code>aws_ecr_image</code> data source): The example uses the <code>"latest"</code> tag to fetch the latest image. If you're using a different tagging strategy, replace <code>"latest"</code> with the specific tag of the image you wish to deploy.</li>
          <li><b>IAM Role Name </b> (<code>aws_iam_role</code> resource): The example creates an IAM role named <code>"ecs_task_execution_role"</code>. You might prefer a different name that aligns with your naming conventions or an existing role.</li>
          <li><b>ECS Task Definition </b> (<code>aws_ecs_task_definition</code> resource): The family, cpu, and memory are essential configurations that should match your application's requirements. Adjust these values based on the needs of your containerized application.</li>
          <li><b>Container Definitions </b>(<code>container_definitions</code> in <code>aws_ecs_task_definition</code>): Adjust the name, cpu, and memory according to your container's specifications.</li>
      </ul>


    </div>
  );
};

export const ECRCdkDoc = () => {
  const terraformCode = `const repository = Repository.fromRepositoryName(this, 'MyRepository', 'your-repo-name');

const ecsTaskExecutionRole = new Role(this, 'ecsTaskExecutionRole', {
  assumedBy: new ServicePrincipal('ecs-tasks.amazonaws.com'),
});

ecsTaskExecutionRole.addManagedPolicy(ManagedPolicy.fromAwsManagedPolicyName('service-role/AmazonECSTaskExecutionRolePolicy'));

const taskDefinition = new FargateTaskDefinition(this, 'YourTask', {
    memoryLimitMiB: 512,
    cpu: 256,
    executionRole: ecsTaskExecutionRole,
});

taskDefinition.addContainer('YourContainer', {
  image: ContainerImage.fromEcrRepository(repository, 'latest'),
  memoryLimitMiB: 512,
  cpu: 256,
  essential: true,
});`
  return (
    <div>
      <p>This guide provides a concise walkthrough for using Container images to create ECS task with AWS Cloud Development Kit (CDK). We use CDK to select an image tag and using the Image in an Amazon ECS Task Definition.</p>

      <h3>Prerequisites</h3>
      <ul>
        <li>AWS CDK installed and setup</li>
        <li>Node.js and npm installed</li>
        <li>AWS account and CLI configured with appropriate permissions</li>
      </ul>

      <p>First, define an ECR repository to fetch details about your repository .Next, define an IAM role for ECS tasks . This role grants necessary permissions for the tasks to run. After that, create an ECS task definition that uses the specified ECR image tag.</p>
      <StyledCodeBlock code={terraformCode} />
      <h3>Parameters</h3>
      <p>Ensure you replace placeholder values (e.g., &lt;your_repo_name&gt;, &lt;YourTask&gt;, &lt;YourContainer&gt;) with actual values relevant to your AWS setup.</p>

      <ul>
        <li><b>Repository Name</b>: The <code>Repository.fromRepositoryName</code> method is used to reference an existing ECR repository by its name. Replace <code>'your-repo-name'</code> with the actual name of your ECR repository.</li>
        <li><b>IAM Role Name</b>: The code creates an IAM role named <code>'ecsTaskExecutionRole'</code>. This name can be changed to match your naming conventions or to reference an existing role that your organization uses for ECS tasks.</li>
        <li><b>Task Definition Name and Configuration</b>: The <code>FargateTaskDefinition</code> is given a name <code>'YourTask'</code>, and it's configured with specific CPU and memory settings. You should adjust the name, <code>memoryLimitMiB</code>, and <code>cpu</code> to suit your application requirements.</li>
    </ul>



    </div>
  );
};

export const ECRawsCli = () => {
  const authenticateDocker = `aws ecr get-login-password --region your-region | docker login --username AWS --password-stdin 879919243408.dkr.ecr.your-region.amazonaws.com`
  const pullImage = `docker pull 879919243408.dkr.ecr.your-region.amazonaws.com/your-repository-name:your-image-tag`

  return (
    <div>
      <p>This guide provides a concise walkthrough for the process of authenticating to an Amazon Elastic Container Registry (ECR) repository and pulling an image using the AWS Command Line Interface (CLI) and Docker, you can follow these steps.</p>
      <h3>Prerequisites</h3>
      <ul>
        <li>AWS CLI Version 2 installed on your machine.</li>
        <li>Docker installed on your machine.</li>
        <li>AWS CLI configured with your credentials.</li>
      </ul>
      <p>First, before pulling images from ECR, you need to authenticate your Docker client to the registry. Use the <code>get-login-password</code> command provided by the AWS CLI to fetch the authentication token, then use <code>docker login</code> to authenticate.</p>
      <StyledCodeBlock code={authenticateDocker} />
      <p>After authentication, you can pull images from your ECR repository using Docker:</p>
      <StyledCodeBlock code={pullImage} />
      <h3>Parameters</h3>
      <p>Replace <code>'your-region'</code> with your AWS region,<code>'your-repository-name'</code> with the name of your ECR repository, and your-image-tag with the tag of the image you wish to pull.</p>
    </div>
  );
};