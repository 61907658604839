/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://api.if2.cloud.audi/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-central-1:aad46977-4cba-4e2e-8384-136589589c82",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_1bGeIeu0K",
    "aws_user_pools_web_client_id": "6hhvsppocnoh289p8mp48oq13v",
    "federatedIdName": "Audi-WASI-SSO",
    "sso_login_only":"true",
    "cookie_domain": ".if2.cloud.audi",
    "cookie_lifetime_hours": "1",
    "oauth": {
        "domain": "if2-prd-sso.auth.eu-central-1.amazoncognito.com",
        "scope": ["openid"],
        "redirectSignIn": ["https://if2.cloud.audi"],
        "redirectSignOut": ["https://if2.cloud.audi/signout"],
        "responseType": "code"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_authenticated_role_arn": "arn:aws:iam::879919243408:role/if2-prd-sso-authenticated",
    "aws_unauthenticated_role_arn": "arn:aws:iam::879919243408:role/if2-prd-sso-unauthenticated",
    "aws_data_submitter_role_arn": "arn:aws:iam::879919243408:role/if2-prd-frontend-data-submitter"
};


export default awsmobile;
