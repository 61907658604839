/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      imageName
      imageDescription
      imageUrl
      architecture
      osType
      createdAt
      ImagePlatforms {
        items {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageName
        imageDescription
        imageUrl
        architecture
        osType
        createdAt
        ImagePlatforms {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImages = /* GraphQL */ `
  query SyncImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        imageName
        imageDescription
        imageUrl
        architecture
        osType
        createdAt
        ImagePlatforms {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImagePlatform = /* GraphQL */ `
  query GetImagePlatform($id: ID!) {
    getImagePlatform(id: $id) {
      platformName
      platformImageID
      platformRegion
      organizationName
      installedLibraries
      imageID
      id
      createdAt
      platformCompositeKey
      ImageCves {
        items {
          id
          imagePlatformId
          imageCveId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImagePlatforms = /* GraphQL */ `
  query ListImagePlatforms(
    $filter: ModelImagePlatformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImagePlatforms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImagePlatforms = /* GraphQL */ `
  query SyncImagePlatforms(
    $filter: ModelImagePlatformFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImagePlatforms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImageCve = /* GraphQL */ `
  query GetImageCve($id: ID!) {
    getImageCve(id: $id) {
      id
      criticality
      message
      package
      cveName
      cveUrl
      imageplatforms {
        items {
          id
          imagePlatformId
          imageCveId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImageCves = /* GraphQL */ `
  query ListImageCves(
    $filter: ModelImageCveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageCves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        criticality
        message
        package
        cveName
        cveUrl
        imageplatforms {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImageCves = /* GraphQL */ `
  query SyncImageCves(
    $filter: ModelImageCveFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImageCves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        criticality
        message
        package
        cveName
        cveUrl
        imageplatforms {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImageBenchmarks = /* GraphQL */ `
  query GetImageBenchmarks($id: ID!) {
    getImageBenchmarks(id: $id) {
      name
      results
      ImagePlatform {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      imageBenchmarksImagePlatformId
      __typename
    }
  }
`;
export const listImageBenchmarks = /* GraphQL */ `
  query ListImageBenchmarks(
    $filter: ModelImageBenchmarksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageBenchmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        results
        ImagePlatform {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        imageBenchmarksImagePlatformId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImageBenchmarks = /* GraphQL */ `
  query SyncImageBenchmarks(
    $filter: ModelImageBenchmarksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImageBenchmarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        name
        results
        ImagePlatform {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        imageBenchmarksImagePlatformId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImagePlatformImageCve = /* GraphQL */ `
  query GetImagePlatformImageCve($id: ID!) {
    getImagePlatformImageCve(id: $id) {
      id
      imagePlatformId
      imageCveId
      imagePlatform {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      imageCve {
        id
        criticality
        message
        package
        cveName
        cveUrl
        imageplatforms {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImagePlatformImageCves = /* GraphQL */ `
  query ListImagePlatformImageCves(
    $filter: ModelImagePlatformImageCveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImagePlatformImageCves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imagePlatformId
        imageCveId
        imagePlatform {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        imageCve {
          id
          criticality
          message
          package
          cveName
          cveUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImagePlatformImageCves = /* GraphQL */ `
  query SyncImagePlatformImageCves(
    $filter: ModelImagePlatformImageCveFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImagePlatformImageCves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        imagePlatformId
        imageCveId
        imagePlatform {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        imageCve {
          id
          criticality
          message
          package
          cveName
          cveUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagePlatformsByImageID = /* GraphQL */ `
  query ImagePlatformsByImageID(
    $imageID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImagePlatformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagePlatformsByImageID(
      imageID: $imageID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagePlatformsByCompositeKey = /* GraphQL */ `
  query ImagePlatformsByCompositeKey(
    $platformCompositeKey: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImagePlatformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagePlatformsByCompositeKey(
      platformCompositeKey: $platformCompositeKey
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagePlatformImageCvesByImagePlatformId = /* GraphQL */ `
  query ImagePlatformImageCvesByImagePlatformId(
    $imagePlatformId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImagePlatformImageCveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagePlatformImageCvesByImagePlatformId(
      imagePlatformId: $imagePlatformId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imagePlatformId
        imageCveId
        imagePlatform {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        imageCve {
          id
          criticality
          message
          package
          cveName
          cveUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagePlatformImageCvesByImageCveId = /* GraphQL */ `
  query ImagePlatformImageCvesByImageCveId(
    $imageCveId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImagePlatformImageCveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagePlatformImageCvesByImageCveId(
      imageCveId: $imageCveId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imagePlatformId
        imageCveId
        imagePlatform {
          platformName
          platformImageID
          platformRegion
          organizationName
          installedLibraries
          imageID
          id
          createdAt
          platformCompositeKey
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        imageCve {
          id
          criticality
          message
          package
          cveName
          cveUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
