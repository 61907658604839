import '@aws-amplify/ui-react/styles.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CustomTopBar} from './CustomComponents/CustomTopBar';
import { CustomImageDetails } from './CustomComponents/CustomImageDetails';
import { CustomImageList } from './CustomComponents/CustomImageList';
import { UsageComponent } from './CustomComponents/UsageComponent';
import { CustomDashboard } from './CustomComponents/CustomDashboard';
import './Styles.css';
import { SupportComponent } from './CustomComponents/SupportComponent';
import { HealthCheckComponent } from './CustomComponents/HealthCheckComponent';
import { Auth, Hub } from 'aws-amplify';
import React, { useState, useEffect, useCallback } from 'react';
import awsExports from './aws-exports';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);


  const checkUserAuthentication = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      redirectBasedOnEnvironment();
    }
  }, []);

  useEffect(() => {
    checkUserAuthentication();
    const unsubscribe = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (['signIn', 'signOut', 'cognitoHostedUI'].includes(payload.event)) {
        checkUserAuthentication();
      }
    });
    return () => unsubscribe(); // Clean up the Hub listener
  }, [checkUserAuthentication]);

  const redirectBasedOnEnvironment = () => {
    if (awsExports.sso_login_only === "true") {
      // For production, redirect to Audi sso login
      setShowRedirectMessage(true); 
      setTimeout(() => {
        Auth.federatedSignIn({provider: awsExports.federatedIdName});
        setShowRedirectMessage(false); // Optionally hide the message after initiating the redirect
      }, 3000);
    } else {
      // For development, show the default Cognito login page
      Auth.federatedSignIn();
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Router>
    <div className="container">
      {showRedirectMessage && <div className="redirect-message">You are being redirected to the Single Sign On page.</div>}
      {isAuthenticated && <CustomTopBar className="customTopBar" signOut={signOut} />}
      <Routes>
        <Route path="/health" element={<HealthCheckComponent />} />
        {/* Ensure other routes are only accessible when authenticated */}
        {isAuthenticated && (
          <>
            <Route path="/" element={<CustomDashboard />} />
            <Route path="/image-list" element={<CustomImageList />} />
            <Route path="/image-details/:slug/:id" element={<CustomImageDetails />} />
            <Route path="/usage" element={<UsageComponent />} />
            <Route path="/support" element={<SupportComponent />} />
          </>
        )}
      </Routes>
    </div>
  </Router>
    );
}
  

export default App;