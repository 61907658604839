import './AudiType.css';

export const tableCveTheme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '#d9d9d9' },
            },
  
            striped: {
              backgroundColor: { value: '#f2f2f2' },
            },
          },
  
          header: {
            color: { value: '#000000' },
            fontSize: '1.063rem',
          },
  
          data: {
            fontWeight: { value: '{fontWeights.normal}' },
            fontFamily: 'AudiType-Normal',
            fontSize: '1.125rem',
          },
        },
      },
    },
  };
  
export const tableImageTheme = {
    name: 'table-theme',
    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: '#d9d9d9' },
            },
            striped: {
              backgroundColor: { value: '#f2f2f2' },
            },
          },
          header: {
            color: { value: '#000000' },
            fontSize: '1.063rem',
          },
          data: {
            fontWeight: { value: '{fontWeights.normal}' },
            fontFamily: 'AudiType-Normal',
            fontSize: '1.125rem',
          },
        },
      },
    },
  };
  
export const expandImageTheme = {
    name: 'expander-theme',
    tokens: {
      components: {
        expander: {
          fontWeight: { value: '{fontWeights.semibold}' },
          backgroundColor: { value: '#d9d9d9' },
          borderRadius: { value: '{radii.small}' },
          trigger: {
            minHeight: { value: '3rem' },
            _hover: {
              backgroundColor: { value: '{colors.overlay.20}' },
            },
           
          },
          content: {
            paddingInlineStart: { value: '{space.xxl}' },
            text: {
              color: { value: '#000000' },
              paddingBlockEnd: { value: '{space.large}' },
              fontWeight: { value: 'bold' }, // Ensures text content is bold
              fontSize: { value: '1.25rem' }, // Sets the font size for text content
            }, 

            _open: {
              animationDuration: { value: '{time.short}' },
            },
          },
        },
      },
    },
  };
  
