import {
  Collection,
  Card,
  Heading,
  View,
  Flex,
  Button,
  Text,
  TextField,
  Badge,
  Image
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listImagePlatforms, listImages } from '../graphql/queries';
import { MdSearch } from 'react-icons/md';
import '../Styles.css';


function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')      // Replace spaces with -
    .replace(/[^\w-]+/g, '')   // Remove all non-word chars
    .replace(/--+/g, '-')      // Replace multiple - with single -
    .replace(/^-+/, '')        // Trim - from start of text
    .replace(/-+$/, '');       // Trim - from end of text

}

export const ImageCollection = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredImages, setFilteredImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchImages = async () => {
    try {
      setIsLoading(true); // Start loading
      // Fetch the list of images
      const response = await API.graphql(graphqlOperation(listImages));
      console.log("Images Response:", response);
      const imageList = response.data.listImages.items;

      const imageIds = imageList.map(img => img.id);

      // Fetch the platforms for those images
      const responsePlatform = await API.graphql(graphqlOperation(listImagePlatforms));
      console.log("Platforms Response:", responsePlatform);
      const platformsData = responsePlatform.data.listImagePlatforms.items;
      const relevantPlatforms = platformsData.filter(platform => imageIds.includes(platform.imageID));

      // Merge platforms with images
      const imagesWithPlatforms = imageList.map(img => {
        const platformsForImage = relevantPlatforms.filter(plat => plat.imageID === img.id);
        const uniquePlatforms = new Set(platformsForImage.map(plat => plat.platformName));
        img.platforms = Array.from(uniquePlatforms).map(platformName => {
          return platformsForImage.find(plat => plat.platformName === platformName);
        });
        return img;
      });
      setImages(imagesWithPlatforms);
      setFilteredImages(imagesWithPlatforms);

    } catch (error) {
      console.error('Error fetching image details:', error);
    } finally {
      setIsLoading(false); // Finish loading
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleButtonClick = (imageName, architecture, id) => {
    const slug = slugify(`${imageName}-${architecture}`, { lower: true });
    navigate(`/image-details/${slug}/${id}`);
  }

  const performSearch = () => {
    // Splitting the searchTerm into individual keywords
    const keywords = searchTerm.toLowerCase().split(" ").filter(word => word);

    const results = images.filter(image => {
      return keywords.every(keyword => {
        return image.imageName.toLowerCase().includes(keyword) ||
          image.architecture.toLowerCase().includes(keyword) ||
          image.platforms.some(platform => platform.platformName.toLowerCase().includes(keyword));
      });
    });

    setFilteredImages(results);
  };

  return (
    <>
      <TextField
        label="Search for Images"
        placeholder='Image Name / Platform / Architecture'
        variant="outlined"
        className="text-field"
        value={searchTerm}
        onChange={e => {
          setSearchTerm(e.target.value);
          performSearch(e.target.value);
        }}
        innerEndComponent={
          <div className="inner-end-component">
            <MdSearch />
          </div>
        }
      />
      {isLoading ? (
        <div className="loader-container" id="loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Collection
          items={filteredImages}
          type="list"
          direction="column"
          gap="0.625rem"
          wrap="wrap"
          isPaginated
        >
          {(item, index) => (
            <Card
              key={index}
              variation="outlined"
              className="card-style"
            >
              <Flex className="flex-row">
                <Flex className='flex-row-item'>
                  <Image
                    alt={item.imageName}
                    src={item.imageUrl}
                    className="image-style"
                  />
                  <View padding="xs">
                    <Heading>{item.imageName}</Heading>
                    <Text>{item.imageDescription}</Text>
                    {item.platforms.map((platform, index) => (
                      <Badge key={index} variation="info" className="badge-style">
                        {platform.platformName}
                      </Badge>
                    ))}
                  </View>
                </Flex>
                <Flex justifyContent="flex-end" alignItems="center">
                  <Button
                    variation="primary"
                    className="button-style"
                    onClick={() => handleButtonClick(item.imageName, item.architecture, item.id)}
                  >
                    View details
                  </Button>
                </Flex>
              </Flex>
            </Card>
          )}
        </Collection>
      )}
    </>
  );
};
