
export const imagePlatformsByCompositeKey = /* GraphQL */ `
  query ImagePlatformsByCompositeKey(
    $platformCompositeKey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelImagePlatformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagePlatformsByCompositeKey(
      platformCompositeKey: $platformCompositeKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        platformCompositeKey
        ImageCves {
          items {
            id
            imagePlatformId
            imageCveId
            imageCve {
              id
              criticality
              message
              package
              cveName
              cveUrl
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const imagePlatformsByImageID = /* GraphQL */ `
  query ImagePlatformsByImageID(
    $imageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImagePlatformFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagePlatformsByImageID(
      imageID: $imageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        platformName
        platformImageID
        platformRegion
        organizationName
        installedLibraries
        imageID
        id
        createdAt
        platformCompositeKey
        ImageCves {
          items {
            id
            imagePlatformId
            imageCveId
            imageCve {
              id
              criticality
              message
              package
              cveName
              cveUrl
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;