import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Divider, Card, Text } from '@aws-amplify/ui-react';
import { ExpandImage } from './ExpandImage';
import { CustomTableImage } from './CustomTableImage';
import { CustomTableCVE } from './CustomTableCVE';
import { ImageDetailCard } from './ImageDetailCard';
import '../Styles.css';


export function CustomImageDetails() {
    const [showCVE, setShowCVE] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [isExpanded, setIsExpanded] = useState(true);
    const [imagePlatformId, setImagePlatformId] = useState(null);
    const [cveFindingsTitle, setCveFindingsTitle] = useState("CVE Findings");

    const showImageTable = (filteredItems) => {
        setSelectedFilters(filteredItems);
        // Toggle the CVE table visibility off if it is currently showing
        if (showCVE) {
            setShowCVE(false);
            setCveFindingsTitle("CVE Findings"); // Reset the title
        }
    };
    // Initial load: Parse filters from URL
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const organizationName = params.get("organizationName");
        const platformName = params.get("platformName");
        const platformRegion = params.get("platformRegion");

        const selectedImagePlatformIdFromURL = params.get("selectedImagePlatformId");
        const platformImageID = params.get("platformImageID");


        // to extract URL parameters and update state
        if (organizationName && platformName && platformRegion) {
            showImageTable({
                organizationName: organizationName,
                platformName: platformName,
                platformRegion: platformRegion,
            });
            setIsExpanded(true); // Ensure that the table is expanded on page load
        }
        if (selectedImagePlatformIdFromURL) {
            setImagePlatformId(selectedImagePlatformIdFromURL);
            setShowCVE(true);
            setCveFindingsTitle(`CVE overview of the Image ID: ${platformImageID}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCVETable = (clickedImagePlatformId, platformImageID) => {
        setShowCVE(true);
        setImagePlatformId(clickedImagePlatformId);
        setCveFindingsTitle(`CVE overview of the Image ID: ${platformImageID}`);
        const params = new URLSearchParams(window.location.search); // Get the current URL search parameters
        params.set("selectedImagePlatformId", clickedImagePlatformId); // Update the 'selectedImagePlatformId' parameter with clicked imagePlatformID
        params.set("platformImageID", platformImageID);
        window.history.pushState({}, '', "?" + params.toString()); // Push the updated search parameters to the browser's address bar

    };

    const handleExpandToggle = (isOpen) => {
        setIsExpanded(isOpen);
        if (!isOpen) {
            setSelectedFilters(null);
            setShowCVE(false);
            setCveFindingsTitle("CVE Findings"); // Reset the title
        }
    };
    return (
        <div className="marginBottom">
            {/* <CustomTopBar className="customTopBar" /> */}
            <Divider />
            <ImageDetailCard className="imageDetailCard" />
            <Divider className="marginBottom" />
            <div className="expandImageContainer">
                <ExpandImage onFilter={showImageTable} onExpandChange={handleExpandToggle} />
                {isExpanded && <CustomTableImage handleCVETable={handleCVETable} imagePlatformId={imagePlatformId} selectedFilters={selectedFilters}
                />}
            </div>
            <div className="cveCard">
                <Card backgroundColor="#d9d9d9" fontFamily='AudiType-ExtendedBold' >
                    <Text className='boldCveTitle'>{cveFindingsTitle}</Text>
                </Card>
                {showCVE && isExpanded && <CustomTableCVE selectedFilters={selectedFilters} imagePlatformId={imagePlatformId} cveFindingsTitle={cveFindingsTitle}
                />}
            </div>
        </div>

    );
}
