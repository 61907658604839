import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ThemeProvider,
  ScrollView,
  Button, Text
} from '@aws-amplify/ui-react';
import { GiHazardSign } from 'react-icons/gi';
import '@aws-amplify/ui-react/styles.css';
import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { imagePlatformsByCompositeKey, imagePlatformsByImageID } from '../custom-queries';
import { useParams } from 'react-router-dom';
import { tableImageTheme } from '../themes';
import moment from 'moment';
import '../Styles.css';
import { Pagination } from '@aws-amplify/ui-react';


export const CustomTableImage = ({ handleCVETable, selectedFilters, setImageCveDetails }) => {
  const [items, setImages] = useState([[]]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [nextToken, setNextToken] = React.useState('');
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);

  const itemsPerPage = 12; 

  const onPrevious = () => {
    setCurrentPageIndex(prev => {
      return prev - 1;
    });
  }

  const onPageChange = (currentPage) => {
    setCurrentPageIndex(prev => {
      return currentPage;
    });
  }

  // Calculate the vulnerability counts based on criticality.
  const calculateVulnerabilityCounts = (imageCveDetails) => {
    const counts = {
      'CRITICAL': 0,
      'HIGH': 0,
      'MEDIUM': 0,
    };
    imageCveDetails.forEach(item => {
      const severity = item.imageCve.criticality;
      if (counts.hasOwnProperty(severity)) {
        counts[severity]++;
      } else {
        console.warn(`Unexpected severity value encountered: ${severity}`);
      }
    });
    return counts;
  };

  
  const fetchImages = async (initial) => {
    try {
      // If this is the initial load, reset the images state
      if (initial) {
        setImages([[]]);
      }
      setLoading(true);
      
      // check if filters are applied
      const isFiltered = !!(
        selectedFilters.organizationName ||
        selectedFilters.platformName ||
        selectedFilters.platformRegion
      );

      // Construct platformCompositeKey if filters are applied, otherwise use imageID
      const platformCompositeKey = isFiltered
        ? `${id}-${selectedFilters.organizationName}-${selectedFilters.platformName}-${selectedFilters.platformRegion}`
        : '';
      const imageID = isFiltered ? '' : id;
  
      if (platformCompositeKey) {
        console.log('platformCompositeKey:', platformCompositeKey);
      } else if (isFiltered) {
        console.error('platformCompositeKey is null or undefined');
      }

      // Choose the appropriate query and set query variables based on filtering and pagination state
      const query = isFiltered
        ? imagePlatformsByCompositeKey
        : imagePlatformsByImageID;
      const variables = {
        limit: itemsPerPage,
        nextToken: initial ? undefined : nextToken,
        sortDirection: 'DESC',
        ...(isFiltered ? { platformCompositeKey} : { imageID }),
      };
  
      const response = await API.graphql(graphqlOperation(query, variables));

    // Set the next token for pagination
      const nextTokenKey = isFiltered
        ? 'imagePlatformsByCompositeKey'
        : 'imagePlatformsByImageID';
      setNextToken(response.data[nextTokenKey].nextToken || '');
    
      // Extract items from the response and calculate vulnerability counts
      const items = response.data[nextTokenKey].items;
      const imageItems = items.map(item => ({
        ...item,
        vulnerabilityCounts: calculateVulnerabilityCounts(item.ImageCves.items),
      }));
      
      // Update the images state with the fetched items
      setImages(prevImages => {
        if (initial) {
          // For the initial load, replace the current images if there are new items
          return imageItems.length > 0 ? [imageItems] : prevImages;
        } else {
        // For subsequent loads, append the new items to the existing images
          return [...prevImages, imageItems];
        }
      });

      // Increment the page index for subsequent loads (not for the initial load)
      if (!initial) {
        setCurrentPageIndex(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error fetching image details:', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchImages(true);
    setCurrentPageIndex(1); 
    // eslint-disable-next-line
  }, [selectedFilters, id, setImageCveDetails]);

  const onNext = async() => {
    const newPageIndex = currentPageIndex + 1;
    if(newPageIndex< items.length){
      setCurrentPageIndex(prev=>{
        return prev + 1;
      });
    }
    else{
      fetchImages(false);
    }
  }


  const handleRowClick = (imagePlatformId, cveFindingsTitle) => {
    handleCVETable(imagePlatformId, cveFindingsTitle);
  };

  //Moving the tooltip generation logic to dedicated functions
  const showTooltip = (e, level) => {
    const tooltip = document.createElement('div');
    tooltip.className = `tooltip tooltip-${level}`;
    tooltip.innerText = level.charAt(0).toUpperCase() + level.slice(1); // Convert 'critical' to 'Critical'
    e.currentTarget.appendChild(tooltip);
  }

  const showCriticalTooltip = (e) => showTooltip(e, 'critical');
  const showHighTooltip = (e) => showTooltip(e, 'high');
  const showMediumTooltip = (e) => showTooltip(e, 'medium');

  const removeTooltip = (e) => {
    e.currentTarget.lastChild.remove();
  };


  return (
    loading ? (
      // Render loader
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    ) :
      items[0].length > 0 ? (
        <ThemeProvider theme={tableImageTheme} colorMode="light">
          <ScrollView className="scrollView">
            <Table highlightOnHover variation="striped">
              <TableHead>
                <TableRow style={{ fontFamily: 'AudiType-ExtendedBold', fontSize: '0.938rem' }}>
                  <TableCell as="th">Organization</TableCell>
                  <TableCell as="th">Region</TableCell>
                  <TableCell as="th">Cloud Provider</TableCell>
                  <TableCell as="th">Image ID</TableCell>
                  <TableCell as="th">Creation Date</TableCell>
                  <TableCell as="th">Vulnerability</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items[currentPageIndex-1] && items[currentPageIndex-1].map((item, index) => (
                  <TableRow key={index} fontFamily='AudiType-Normal'>
                    <TableCell>{item.organizationName}</TableCell>
                    <TableCell>{item.platformRegion}</TableCell>
                    <TableCell>{item.platformName}</TableCell>
                    <TableCell>
                      {item.vulnerabilityCounts && item.vulnerabilityCounts['CRITICAL'] > 0
                        ? <span className="label label-danger">NOT COMPLIANT</span>
                        : item.platformImageID}
                    </TableCell>
                    <TableCell>
                      {moment(item.createdAt).fromNow()}
                    </TableCell>
                    <TableCell className="cellColor" >
                      <span className="tooltipParent" onMouseOver={showCriticalTooltip} onMouseOut={removeTooltip}>
                        <GiHazardSign className="hazardSignOne" />
                      </span>
                      {item.vulnerabilityCounts ? item.vulnerabilityCounts['CRITICAL'] : 0}

                      <span className="tooltipParent" onMouseOver={showHighTooltip} onMouseOut={removeTooltip}>
                        <GiHazardSign className="hazardSignTwo" />
                      </span>
                      {item.vulnerabilityCounts ? item.vulnerabilityCounts['HIGH'] : 0}

                      <span className="tooltipParent" onMouseOver={showMediumTooltip} onMouseOut={removeTooltip}>
                        <GiHazardSign className="hazardSignThree" />
                      </span>
                      {item.vulnerabilityCounts ? item.vulnerabilityCounts['MEDIUM'] : 0}

                      {item.vulnerabilityCounts && item.vulnerabilityCounts['CRITICAL'] <= 0 && (
                        <Button
                          size="small"
                          className="customButton"
                          onClick={() => {
                            handleRowClick(item.id, item.platformImageID);
                          }}
                        >
                          More Info
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollView>
          <Pagination
            currentPage={currentPageIndex}
            totalPages={items.length}
            hasMorePages={nextToken!==''}
            onNext={onNext}
            onPrevious={onPrevious}
            onChange={onPageChange}
          />
        </ThemeProvider>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '12.5rem',
          padding: '1.25rem',
          boxSizing: 'border-box',
        }}>
          <Text style={{
            textAlign: 'center',
            width: '100%',
          }}>
            No images available for the selected filters.
          </Text>
        </div>)
  );
};

