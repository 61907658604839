import React from 'react';
import '../Styles.css';
import { FiExternalLink } from 'react-icons/fi'; // Importing the external link icon

export const SupportComponent = () => {
    return (
        <div className="support-container">
            <h2 className="support-title">Support Center</h2>
            <p className="support-content">
                Welcome to our Support Center. If you have any inquiries or require assistance, we're here to help.
                <br />
                Please&nbsp;
                <a href="mailto:imagefactory@audi.de" target="_blank" rel="noopener noreferrer" className="ticket-link">
                    reach out to us
                    <FiExternalLink className="redirect-icon" />
                </a>
                &nbsp; or create a ticket in SC3 using the group [<strong>IMAGEFACTORY Support VW Group</strong>].
            </p>

            {/* <a href="imagefactory@audi.de" target="_blank" rel="noopener noreferrer" className="ticket-link">
                Create a Ticket
                <FiExternalLink className="redirect-icon" />
                (with SC3 Group name: IMAGEFACTORY Support VW Group)
            </a> */}
        </div>
    );
};