import {
  Card,
  View,
  Heading,
  Flex,
  Text,
  Image
} from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getImage } from '../graphql/queries';
import { useParams } from 'react-router-dom';
import '../Styles.css';
import '../AudiType.css';

export const ImageDetailCard = () => {
  // Using the 'useParams' hook to extract parameters from the URL
  const { id } = useParams();
  const [imageDetail, setImageDetail] = useState(null);
  useEffect(() => {
    console.log('Fetching details for image ID:', id);
    const fetchImageDetails = async () => {
      try {
        const response = await API.graphql(graphqlOperation(getImage, { id }));
        setImageDetail(response.data.getImage);
      } catch (error) {
        console.error('Error fetching image details:', error);
      }
    };
    fetchImageDetails();
  }, [id]);

  // Handle loading state
  if (!imageDetail) return <div>Loading...</div>;
  return (
    <View className="view-container">
      <Card>
        <Flex className="row-flex">
          <Image
            alt={imageDetail.imageName}
            src={imageDetail.imageUrl}
            className="image-width"
          />
          <Flex className="column-flex">
            <Heading level={6} fontFamily='AudiType-ExtendedBold' fontSize={'0.938rem'}>
              {imageDetail.imageName}
            </Heading>
            <Text as="span" level={6} fontFamily='AudiType-ExtendedBold' fontSize={'0.938rem'}>
              {imageDetail.imageDescription}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </View>
  );
};

