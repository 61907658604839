import React from 'react';


export const CustomDashboard = () => {
    return (
        <>
            <div className="dashboard-container">
                <header className="dashboard-header">
                    <h1>Welcome to GITC Imagefactory</h1>
                    {/*<div className="notice-banner">
                        <p>
                            Notice: We would like to inform you that our Image Factory will undergo scheduled maintenance on 21st August from 12:00 to 17:00. During this period, the application frontend might be temporarily unavailable. 
                        </p>
                    </div> */}
                </header>
                <main className="dashboard-main">
                    <section className="dashboard-section">
                        <p>
                            For detailed information about the Image Factory project,
                            please refer to our <a href="https://volkswagen-net.de/wikis/display/AudiCLOUD/Image+Factory+2.0"
                                target="_blank"
                                rel="noopener noreferrer">
                                Group Wiki Documentation
                            </a>.
                        </p>
                    </section>
                </main>
            </div>
            <div>
            </div>
            <div className="dashboard-footer">
            <footer className="footer-content">
                    <p><b>Disclaimer:</b>The images distributed through the Image Factory are proprietary and confidential assets solely intended for internal software usage within the Volkswagen Group. Under no circumstances should these images be integrated into vehicles or any end customer products. These images are approved exclusively for Audi's proposed applications and any usage beyond the specified scope is strictly prohibited. Please note that if you choose to install additional software or to modify the images, you are responsible for conducting the necessary open-source scans and ensuring compliance with all relevant regulations and licenses. Open-source scans on the provided images are currently in development.</p>
            </footer>
            </div>

        </>
    );
};

