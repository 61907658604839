import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copyIcon from '../Logo/copy-icon.png';
import '../Styles.css';

export const StyledCodeBlock = ({ code, language = 'javascript' }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(code).then(
            () => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
            },
            (error) => {
                console.error('Copy failed', error);
                window.alert('Failed to copy');
            }
        );
    };

    return (
        <div className={`code-container ${copied ? 'copied' : ''}`}>
            <SyntaxHighlighter language={language} style={okaidia}>
                {code}
            </SyntaxHighlighter>
            <button className="copy-button" onClick={copyToClipboard} aria-label="Copy code to clipboard">
                {copied ? (
                    // Change button style upon successful copy
                    <span className="copy-feedback">Copied!</span>
                ) : (
                    <img src={copyIcon} alt="Copy" className="copy-icon" />
                )}
            </button>
        </div>
    );
};