import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ThemeProvider,
  ScrollView,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { imagePlatformImageCvesByImagePlatformId } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { tableCveTheme } from '../themes';
import '../Styles.css';


export const CustomTableCVE = ({ selectedFilters, imagePlatformId }) => {
  const [items, setImages] = useState([]);
  const { id } = useParams();

  const getSeverityOrder = (severity) => {
    switch (severity) {
      case 'CRITICAL':
        return 1;
      case 'HIGH':
        return 2;
      case 'MEDIUM':
        return 3;
      default:
        return 4;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await API.graphql(graphqlOperation(imagePlatformImageCvesByImagePlatformId, { imagePlatformId}));
        const imageItems = response.data.imagePlatformImageCvesByImagePlatformId.items;

        // Sort based on severity
        imageItems.sort((a, b) => getSeverityOrder(a.imageCve.criticality) - getSeverityOrder(b.imageCve.criticality));

        setImages(imageItems);
      } catch (error) {
        console.error('Error fetching image details:', error.errors[0].message);
      }
    };
    fetchImages();
  }, [selectedFilters, imagePlatformId, id]);

  return (
    <ThemeProvider theme={tableCveTheme} colorMode="light">
      <ScrollView className="scrollView" >
        <Table highlightOnHover variation="striped">
          <TableHead fontFamily='AudiType-ExtendedBold'>
            <TableRow>
              <TableCell as="th">CVE ID</TableCell>
              <TableCell as="th">Description</TableCell>
              <TableCell as="th">Severity</TableCell>
              <TableCell as="th">Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}fontFamily='AudiType-Normal'>
                <TableCell>{item.imageCve.cveName}</TableCell>
                <TableCell className='justify-text'>{item.imageCve.message}</TableCell>
                <TableCell>{item.imageCve.criticality}</TableCell>
                {/* <TableCell>{item.imageCve.cveUrl}</TableCell> */}
                <TableCell>
                  <a href={item.imageCve.cveUrl} target="_blank" rel="noopener noreferrer">
                    {item.imageCve.cveUrl}
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </ScrollView>
    </ThemeProvider>

  );
};
