import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider} from "@aws-amplify/ui-react";
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports';

// Function to determine the cookie domain
const getCookieDomain = (config) => {
  // Use cookie_domain from awsExports if it exists
  if (config.cookie_domain) {
    return config.cookie_domain;
  }

  // If cookie_domain is not specified, parse the domain from oauth.redirectSignIn[0]
  const signInURL = config.oauth.redirectSignOut[0];
  const urlWithoutProtocol = signInURL.split("//")[1];
  let domain = urlWithoutProtocol.split('/')[0]; // Remove any path
  domain = domain.split(':')[0]; // Remove port if present

  if (!domain.startsWith('.')) {
    domain = '.' + domain;
  }
 
  return domain;
};

const cookieDomain = getCookieDomain(awsExports);

Amplify.configure({
  Auth: {
    cookieStorage: {
      domain: cookieDomain,
      path: '/',
      expires: (awsExports.cookie_lifetime_hours || 1) / 24, //default 1 hour
      secure: true
    },
    region: awsExports.aws_project_region,
    identityPoolId: awsExports.aws_cognito_identity_pool_id,
    userPoolId: awsExports.aws_user_pools_id,
    userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
    oauth: {
      domain: awsExports.oauth.domain,
      scope: awsExports.oauth.scope,
      redirectSignIn: awsExports.oauth.redirectSignIn[0],
      redirectSignOut: awsExports.oauth.redirectSignOut[0],
      responseType: awsExports.oauth.responseType
    },
  },
  aws_appsync_graphqlEndpoint: awsExports.aws_appsync_graphqlEndpoint,
  aws_appsync_region: awsExports.aws_project_region,
  aws_appsync_authenticationType: awsExports.aws_appsync_authenticationType
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={ThemeProvider}>
      <App />
  </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
