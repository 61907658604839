import React, { useEffect, useState } from 'react';
import awsmobile from '../aws-exports';


export const HealthCheckComponent = () => {
  const [status, setStatus] = useState('Checking backend status...');

  useEffect(() => {
    const triggerAppSync = async () => {
      try {
        const response = await fetch(awsmobile.aws_appsync_graphqlEndpoint);
        if (response.status === 400) {
          setStatus('200 Ok.');
        } else {
          setStatus('503 unavailable.');
        }
      } catch (error) {
        console.error('Error triggering AppSync:', error);
        setStatus('503 unavailable.');
      }
    };
    triggerAppSync();
  }, []);


  return (
    <div>
      <h1>Health Check Status</h1>
      <p>{status}</p>
    </div>
  );
};
