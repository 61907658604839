import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Flex, Text, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '../Styles.css';
import logo from '../Logo/gitc-logo.png';

// This Topbar was created by Figma
const TopBar = ({ overrides, ...rest }) => {
  return (
    <Flex
      className="flex-bar"
      marginBottom="3.75rem"
      {...rest}
    >
      <Image src={logo} alt="Audi Logo" className="logo-image" />
      <Text className="text-style" {...overrides["Dashboard"]}></Text>
      <Text className="text-style" {...overrides["Image List"]}></Text>
      <Text className="text-style" {...overrides["Usage"]}></Text>
      <Text className="text-style" {...overrides["Support"]}></Text>
      <button className="sign-out-button" {...overrides["SignOut"]}>
        Sign Out
      </button>
    </Flex>
  );
};

export function CustomTopBar({ signOut, ...props }) {
    const location = useLocation();

    const getMenuItemOverrides = (menuItem, linkPath) => {
        const isActive = location.pathname === linkPath;
        return {
            children: (
                <Link to={linkPath} className={isActive ? 'active-link' : ''}>
                    {menuItem}
                </Link>
            ),
        };
    };

    const overrides = {
        ...props.overrides,
        "Dashboard": getMenuItemOverrides("Dashboard", "/"),
        "Image List": getMenuItemOverrides("Image List", "/image-list"),
        "Usage": getMenuItemOverrides("Usage", "/usage"),
        "Support": getMenuItemOverrides("Support", "/support"),
        "SignOut": {
            onClick: signOut
        }
    };

    return <TopBar {...props} overrides={overrides} />;
}
