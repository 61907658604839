import React, { useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import '../Styles.css';
import { AWSTerraformDoc, AWSCdkDoc, ECRTerraformDoc,ECRCdkDoc, ECRawsCli} from './UsageTabContents'

const TabButton = ({ isSelected, onClick, children }) => (
  <button
    className={`tab-button ${isSelected ? 'selected' : ''}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const ContentBlock = ({ children }) => (
  <div className="document">
    {children}
  </div>
);


export const UsageComponent = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isSecondExpanded, setIsSecondExpanded] = useState(false);
   const [isThirdExpanded, setIsThirdExpanded] = useState(false);

  const [selectedOption, setSelectedOption] = useState('terraform');
  // const [secondSelectedOption, setSecondSelectedOption] = useState('terraform');
  const [thirdSelectedOption, setThirdSelectedOption] = useState('terraform');


  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) setSelectedOption('terraform');
  };
  // const toggleSecondExpand = () => {
  //   setIsSecondExpanded(!isSecondExpanded);
  //   if (!isSecondExpanded) setSecondSelectedOption('terraform');
  // };
  const toggleThirdExpand = () => {
    setIsThirdExpanded(!isThirdExpanded);
    if (!isThirdExpanded) setThirdSelectedOption('terraform');
  };

  const selectOption = (option) => {
    setSelectedOption(option);
  };

  // const selectSecondOption = (option) => {
  //   setSecondSelectedOption(option);
  // };
  const selectThirdOption = (option) => {
    setThirdSelectedOption(option);
  };

  return (
    //  AWS  //
    <div className="deployment-container">
      <div className="expandable-header" onClick={toggleExpand}>
        <h2>How to deploy images in AWS platform</h2>
        <div className={`arrow ${isExpanded ? 'up' : 'down'}`}></div>
      </div>

      {isExpanded && (
        <>
          <div className="tabs">
            <TabButton
              isSelected={selectedOption === 'terraform'}
              onClick={() => selectOption('terraform')}
            >
              Terraform
            </TabButton>
            <TabButton
              isSelected={selectedOption === 'cdk'}
              onClick={() => selectOption('cdk')}
            >
              CDK
            </TabButton>
          </div>

          <div className="content">
            {selectedOption === 'terraform' && (
              <ContentBlock>
                <AWSTerraformDoc />
              </ContentBlock>
            )}
            {selectedOption === 'cdk' && (
              <ContentBlock>
                <AWSCdkDoc />
              </ContentBlock>
            )}
          </div>
        </>
      )}

      {/*// AZURE  // 

      <div className="expandable-header" onClick={toggleSecondExpand} style={{ marginTop: '2rem' }}>
        <h2>How to deploy images in AZURE platform</h2>
        <div className={`arrow ${isSecondExpanded ? 'up' : 'down'}`}></div>
      </div>

      {isSecondExpanded && (
        <>
          <div className="tabs">
            <TabButton
              isSelected={secondSelectedOption === 'terraform'}
              onClick={() => selectSecondOption('terraform')}
            >
              Terraform
            </TabButton>
            <TabButton
              isSelected={secondSelectedOption === 'cdk'}
              onClick={() => selectSecondOption('cdk')}
            >
              CDK
            </TabButton>
          </div>

          <div className="content">
            {secondSelectedOption === 'terraform' && <ContentBlock>Terraform Documentation</ContentBlock>}
            {secondSelectedOption === 'cdk' && <ContentBlock>CDK Documentation</ContentBlock>}
          </div>
        </>
      )}*/}
     {/*//  AWS ECR  // */}
      <div className="expandable-header" onClick={toggleThirdExpand} style={{ marginTop: '2rem' }}>
        <h2>How to use images in ECS Task</h2>
        <div className={`arrow ${isThirdExpanded ? 'up' : 'down'}`}></div>
      </div>
      {isThirdExpanded && (
        <>
          <div className="tabs">
            <TabButton
              isSelected={thirdSelectedOption === 'terraform'}
              onClick={() => selectThirdOption('terraform')}
            >
              Terraform
            </TabButton>
            <TabButton
              isSelected={thirdSelectedOption === 'cdk'}
              onClick={() => selectThirdOption('cdk')}
            >
              CDK
            </TabButton>   
            <TabButton
              isSelected={thirdSelectedOption === 'awsCli'}
              onClick={() => selectThirdOption('awsCli')}
            >
              AWS CLI
            </TabButton>
          </div>

          <div className="content">
            {thirdSelectedOption === 'terraform' && 
            <ContentBlock> 
              <ECRTerraformDoc />
            </ContentBlock>}
            {thirdSelectedOption === 'cdk' && 
            <ContentBlock>
              <ECRCdkDoc></ECRCdkDoc>
            </ContentBlock>}
            {thirdSelectedOption === 'awsCli' && 
            <ContentBlock>
              <ECRawsCli></ECRawsCli>
            </ContentBlock>}
          </div>
        </>
      )} 
    </div>
   
         
  );
};
